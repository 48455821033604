const firebaseConfigDev = {
  apiKey: "AIzaSyDsbX7y95BuShsLAlJM1vEgckH99EBjJz4",
  authDomain: "faves-web-dev.firebaseapp.com",
  databaseURL: "https://faves-web-dev.firebaseio.com",
  projectId: "faves-web-dev",
  storageBucket: "faves-web-dev.appspot.com",
  messagingSenderId: "138845984717",
  appId: "1:138845984717:web:f103d5655feaa3e3351684",
  measurementId: "G-24XSPBNJX7"
};

const firebaseConfigProd = {
  apiKey: "AIzaSyCMOBGGV1nUX9J2hvBMfhZLy8QVKV15ilA",
  authDomain: "faves-pro.firebaseapp.com",
  databaseURL: "https://faves-pro.firebaseio.com",
  projectId: "faves-pro",
  storageBucket: "faves-pro.appspot.com",
  messagingSenderId: "997297102399",
  appId: "1:997297102399:web:376a112501fc789013a28a",
  measurementId: "G-086GFBQY8G"
};

export const isProd = process.env.REACT_APP_ENV === "production";
export const firebaseConfig = isProd ? firebaseConfigProd : firebaseConfigDev;
