import React, { useEffect, useState } from "react";
import { sendRequestForCode, generateToken, auth, logout } from "../firebase";
import { isProd } from "../configuration"
import CodeForm from "./CodeForm";
import PhoneNumberForm from "./PhoneNumberForm";

export default function AppContent() {
  const [requestSent, setRequestSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [customTokenSaved, setCustomTokenSet] = useState(false);
  const scope = "AppContent";

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setIsLoggedIn(true);

        if (!customTokenSaved) {
          generateToken()
            .then(() => {
              setErrorMessage("");
              setCustomTokenSet(true);
            }).finally(() => setIsLoading(false))
        }
      } else {
        setIsLoggedIn(false);
      }
    });
    return () => unsubscribe();
  }, [customTokenSaved]);

  function handlePhoneNumberForm(phoneNumber) {
    setIsLoading(true);

    return sendRequestForCode(phoneNumber)
      .then(() => {
        setRequestSent(true);
        setErrorMessage("");
      }).catch(() => {
        setErrorMessage("An error occurred verifying the phone number associated with this account. Please contact support@favespro.com for assistance.");
      }).finally(() => setIsLoading(false));
  }

  function handleCodeForm(code) {
    setIsLoading(true);

    const confirmation = window.confirmationResult;

    return confirmation.confirm(code)
      .catch(() => {
        setErrorMessage("Sorry, the code you entered is incorrect or expired. Please check the number and try again.");
      })
      .finally(() => setIsLoading(false));
  }

  if (isLoggedIn) {
    return (
      <div className="LoggedInInfo">
        <h1 className={`${scope}-header`}>You're signed in!</h1>
        <div className={`${scope}-message`}>
          <div>To upload orders, click the FAVES extension icon,</div>
          <div>then click the Upload button.</div>
        </div>
        <div className={`${scope}-message`}>
          <div>Need help?</div>
          <a href = "mailto: support@favespro.com">support@favespro.com</a>
        </div>
        <div className={`${scope}-message`}>
          You can close this page now.
        </div>
        <button className="Button" onClick={() => {
          window.close();
        }}>Close</button>
        {!isProd && <button className="Button" onClick={() => {
          setRequestSent(false);
          return logout()
        }}>LOGOUT</button>}
      </div>
    )
  }

  return(
    <div className={scope}>
      <h1 className={`${scope}-header`}>Sign in to use the FAVES Pro uploader</h1>
      <div className={`${scope}-formWrapper`}>
        { requestSent ?
          <CodeForm
            handleForm={handleCodeForm}
            isLoading={isLoading}
          />
          :
          <PhoneNumberForm
            handleForm={handlePhoneNumberForm}
            isLoading={isLoading}
          />
        }
      </div>
      <div className={`${scope}-errorMessage`}>{errorMessage}</div>
      <div className={`${scope}-info`}>
        <div>You must have a FAVES Pro subscription to use the uploader.</div>
        <div>Learn more at <a href="https://favespro.com" target="_blank" rel="noreferrer">favespro.com</a></div>
      </div>
    </div>
  )
}
