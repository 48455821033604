/* global grecaptcha */
/* global chrome */

import { initializeApp } from "firebase/app";
import { getAuth, signInWithPhoneNumber, signOut, RecaptchaVerifier } from "firebase/auth";
import { getFunctions, httpsCallable } from "firebase/functions";
import { firebaseConfig } from "./configuration";

const app = initializeApp(firebaseConfig);
export const auth = getAuth();
const functions = getFunctions(app);

export function logout() {
  return signOut(auth);
}

export function generateToken() {
  const generateTokenFunction = httpsCallable(functions, "generateCustomTokenForExtension");

  return generateTokenFunction()
    .then((response) => {
      const { extensionId, token } = response.data;

      return chrome.runtime?.sendMessage(extensionId, { token });
    });
}

export function sendRequestForCode(phoneNumber) {
  initRecaptcha();
  const appVerifier = window.recaptchaVerifier;
  const validatedPhoneNumber = phoneNumber
    .replaceAll("(", "")
    .replaceAll(")", "")
    .replaceAll(" ", "")
    .replaceAll("-", "")

  return signInWithPhoneNumber(auth, validatedPhoneNumber, appVerifier)
    .then((confirmationResult) => {
      window.confirmationResult = confirmationResult;
      resetRecaptcha();

      return confirmationResult;
    })
    .catch((error) => {
      resetRecaptcha();

      throw error;
    });
}

export function initRecaptcha() {
  if (!window.recaptchaVerifier) {
    window.recaptchaVerifier = new RecaptchaVerifier("recaptcha-container", {
      "size": "invisible",
    }, auth);
  }
}

export function resetRecaptcha() {
  return window.recaptchaVerifier.render().then((widgetId) => {
    return grecaptcha.reset(widgetId);
  });
}
