import React from "react";
import AppContent from "./components/AppContent";
import intro from "./images/intro.jpg";
import logo from "./images/logo.png";
import "./App.css";

function App() {
  const scope = "App";

  return (
    <div className={scope}>
      <img className={`${scope}-logo`} src={logo} alt="FAVES" />
      <img className={`${scope}-introImage`} src={intro} alt="INTRO" />
      <AppContent />
    </div>
  );
}

export default App;
