import React, { useState } from "react";

export default function CodeForm(props) {
  const scope = "Form";
  const { handleForm, isLoading, recaptcha } = props;
  const [inputValue, setInputValue] = useState("");

  function onSubmit(event) {
    event.preventDefault();

    handleForm(inputValue);
  }

  return <form name="login-form" className={scope} onSubmit={onSubmit}>
    <p className={`${scope}-description`}>Enter the code you received on your phone:</p>
    <input
      className={`${scope}-input ${scope}-codeInput`}
      value={inputValue}
      type="text"
      placeholder="Code"
      onChange={(event) => setInputValue(event.target.value)}
      disabled={isLoading}
    />
    <button
      id="sign-in-button"
      type="submit"
      className="Button"
      disabled={isLoading}
    >
      Sign In
    </button>
    {recaptcha && <div id="recaptcha-container" />}
  </form>
}
