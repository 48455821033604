import React, { useState } from "react";

export default function PhoneNumberForm(props) {
  const scope = "Form";
  const { handleForm, isLoading } = props;
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("+1");

  function onSubmit(event) {
    event.preventDefault();

    handleForm(`${countryCode} ${phoneNumber}`);
  }

  return <form name="login-form" className={scope} onSubmit={onSubmit}>
    <p className={`${scope}-description`}>Enter the phone number associated with your FAVES Pro account:</p>
    <div className={`${scope}-inputsWrapper`}>
      <input
        className={`${scope}-input ${scope}-countryCodeInput`}
        value={countryCode}
        type="text"
        onChange={(event) => setCountryCode(event.target.value)}
        disabled={isLoading}
        required
      />
      <input
        className={`${scope}-input`}
        value={phoneNumber}
        type="text"
        placeholder="Phone Number"
        onChange={(event) => setPhoneNumber(event.target.value)}
        disabled={isLoading}
        required
      />
    </div>
    <button
      id="sign-in-button"
      type="submit"
      className="Button"
      disabled={isLoading}
    >
      Continue
    </button>
    <div id="recaptcha-container" />
  </form>
}
